<template>
  <section>
    <a-page-header @back="() => $router.go(-1)" :class="{ loading }" style="margin-bottom: 24px;">
      <template #title><span :style="{ marginRight: '1rem' }">{{ pageTitle }}</span></template>
      <template #extra>
        <p class="objCode">Код объекта: {{ object?.code }}</p>
      </template>
    </a-page-header>

    <a-skeleton :loading="loading">

      <a-row :gutter="32" :style="{ marginBottom: '0.5rem' }">
        <a-col :xs="{ span: 10 }" :xl="{ span: 8 }">
          <app-image :src="object?.previewUrl" width="100%" height="260px" />
          <span :class="{ 'cursor-none': loadingFavourites }"
                :style="{ fontSize: '1.25rem', zIndex: '9', color: '#FFFFFF !important', float: 'right', marginTop: -250 + 'px', marginRight: 25 + 'px' }"
          >
            <loading-outlined v-if="loadingFavourites" style="color: #FFFFFF;" class="clickable-image" />
            <heart-filled v-else-if="object?.favourite" class="error-color-text clickable-image"
                          @click="changeFavourite(false)" />
            <heart-outlined v-else class="grayscale-gray-color-text clickable-image" @click="changeFavourite(true)" />
          </span>
        </a-col>
        <a-col :xs="{ span: 14 }" :xl="{ span: 16 }">
          <a-descriptions layout="vertical" :column="1">
            <a-descriptions-item label="Местоположение">
              {{ renderObjectPath(object?.path || [], false, ', ') || 'Верхний уровень' }}
              <a-button type="link" v-if="mapObjectId" style="padding: 0;">
                <template #icon>
                  <environment-outlined />
                </template>

                <router-link :to="{ name: 'mapView', params: { id: mapObjectId, objectId: object?.id } }">
                  Показать на карте
                </router-link>
              </a-button>
            </a-descriptions-item>
            <a-descriptions-item label="Атрибуты">

              <ul class="small" v-if="attributesFiltered?.length > 0">
                <li v-for="   attributeParent    in    attributesFiltered   " :key="attributeParent?.id">
                  <span>{{ attributeParent?.name }}</span>
                  <template v-if="attributeParent?.value !== null">:
                    {{ attributeParent?.value }}
                  </template>
                  <ul v-if="attributeParent?.attributes">
                    <li v-for="   attribute    in    attributeParent?.attributes   " :key="attribute?.id">
                      <span>{{ attribute?.name }}</span>
                      <template v-if="attribute?.value !== null">:
                        {{ attribute?.value }}
                      </template>
                    </li>
                  </ul>
                </li>
              </ul>
              <a-form-item v-if="object.objectType.name === 'Переговорная'" label="Комфортный кабинет"
                class="form-item-switch">
                <a-switch v-model:checked="isActiveSystem" />
              </a-form-item>
              <!-- <p v-else>&mdash;</p> -->
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
      <a-tabs v-model:active-key="currentTab">
        <a-tab-pane key="calendar" tab="График занятости">

          <reservation-calendar :object="object" v-if="object" :disabled="isReserveDisabled" />
        </a-tab-pane>

        <a-tab-pane v-if="object.objectType.name === 'Переговорная'" key="attributes" tab="Системы комфорта"
          :force-render="true">
          <comfort-systems :isActiveSystem="isActiveSystem" />
        </a-tab-pane>

        <a-tab-pane v-if="object.objectType.name === 'Рабочее место' || object.objectType.name === 'Переговорная'"
          key="systems" tab="Рабочие системы" :force-render="true">
          <div @click="systemSoundClick" style="width: 32.5%;     display: flex;
                      align-items: center;
                      height: 72px;
                      padding: 4px 16.333px 4px 4px;
                      align-items: center;
                      border-radius: 12px;
                      border: 1px solid #F2F2F7;">
            <img :src="isActive ? '/images/icons/no-video-camera.svg' : '/images/icons/video-camera.svg'" alt="4Space">
            <div
              style="margin: 0; margin-left: 4px ;display: flex; flex-direction: column; align-self: start; justify-content: center; height: 100%;">

              <p class="comfort-name">Видеоконференц&#8203;связь</p>
              <p style="margin: 0;">{{ isActive ? 'Вкл' : 'Выкл' }}</p>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
      <!-- Компонента календаря-планировщика -->

    </a-skeleton>
  </section>
</template>

<script>
import { computed, ref, unref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import {onMounted} from "@vue/runtime-core";
import {
  LoadingOutlined, EnvironmentOutlined, HeartOutlined,
  HeartFilled,
} from '@ant-design/icons-vue';
import { requestAPI as objectAPI, getOneData as getOneObject } from "@/compositions/objects";
import { notification } from "ant-design-vue";
import AppImage from "@/components/admin/shared/ul/AppImage";
import ReservationCalendar from "@/components/admin/reservation/ReservationCalendar";
import ComfortSystems from "@/components/admin/reservation/ComfortSystems";


export default {
  name: "UserReservationSchedulePage",
  setup() {

    const route = useRoute();
    const router = useRouter();
    const currentTab = ref('calendar');
    const pageTitle = ref('График занятости');
    const loading = ref(true);
    const isActive = ref(false);
    const isActiveSystem = ref(false);
    const loadingFavourites = ref(false);
    const object = ref({});
    const breadcrumbs = ref([]);
    const mapObjectId = computed(() => {
      if (unref(breadcrumbs)?.length === 0) return undefined;
      const parentObject = unref(breadcrumbs)[0];
      return parentObject?.name === 'root' ? 'root' : parentObject?.id;
    });

    const { getObjectById, addObjectToFavourites, removeObjectFromFavourites, getObjectBreadcrumbs } = objectAPI();
    const { renderObjectPath } = getOneObject();
    const attributesFiltered = computed(() => {
      const arr = [];
      unref(object)?.attributes.forEach((topAttribute) => {
        if (topAttribute?.type === 'group' || topAttribute?.value !== null) {
          arr.push({
            ...topAttribute,
            attributes: topAttribute?.attributes?.filter((childAtribute) => childAtribute?.value !== null)
          })
        }
      });
      return arr;
    });

    onMounted(async () => {
      try {
        if (route.params?.id) {
          loading.value = true;
          const res = await getObjectById(route.params?.id, {
            withReservationPolicy: true,
            showMapObjectPermissions: true
          });
          if (res?.id) {
            object.value = res;
            pageTitle.value = res.name;
            breadcrumbs.value = ((await getObjectBreadcrumbs(route.params?.id || 'root'))?.data || []);
          } else {
            notification.error({ message: 'Ошибка', description: 'Объект не найден' });
          }
        } else {
          router.push({ name: 'myReservations' });
        }
        loading.value = false;
      } catch (e) {
        router.push({ name: 'myReservations' });
      }
    });

    function systemSoundClick() {
      this.isActive = !this.isActive;
    }

    async function changeFavourite(newState) {
      loadingFavourites.value = true;
      try {
        if (newState) {
          await addObjectToFavourites(unref(object)?.id);
        } else {
          await removeObjectFromFavourites(unref(object)?.id);
        }
        object.value.favourite = newState;
      } catch (e) {
        console.error(e);
      } finally {
        loadingFavourites.value = false;
      }
    }

    const isReserveDisabled = computed(() => (unref(object)?.permissions || []).indexOf('can_reserve') === -1 || unref(object)?.objectType?.reservationAvailable === false);

    return {
      loading, loadingFavourites,
      pageTitle, object, mapObjectId, renderObjectPath, attributesFiltered,
      addObjectToFavourites, removeObjectFromFavourites, changeFavourite, isReserveDisabled, currentTab, isActiveSystem, isActive, systemSoundClick
    }
  },
  components: {
    ReservationCalendar, AppImage, HeartOutlined,
    HeartFilled, LoadingOutlined, EnvironmentOutlined, ComfortSystems
  }
}
</script>


<style lang="less" >
.ant-form-item-label>label {
  color: #1A1A23 !important;

  font-family: Aeroport !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 19px !important;
}

.ant-form-item.form-item-switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-descriptions-item-label {
  color: #1C1C1E !important;

  font-family: Aeroport;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.38px;
}

.ant-descriptions-item-content {
  display: flex !important;
  justify-content: space-between;
}


.objCode {
  margin: 1rem 0 0;
  color: rgba(26, 26, 35, 0.48);
  font-family: Aeroport;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  /* 135.714% */
}

.ant-tabs-nav .ant-tabs-tab {
  font-family: Aeroport;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.38px;
}

.ant-tabs-bar {
  margin-bottom: 24px;
}

.grayscale-gray-color-text {
  color: #FFF !important;
}
</style>
