<template>
  <section class="reservation-calendar">
    <a-row type="flex" justify="space-between" :style="{ marginBottom: '1.5rem' }">
      <!-- <a-col>
        <h5>
          <span :style="{ marginRight: '1rem' }">График занятости</span>
          <a-spin v-if="loading" />
        </h5>
        <div class="error-color-text" v-if="disabled">Бронирование объекта запрещено</div>
      </a-col> -->
      <div :style="{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
        <a-week-picker style="width: 80%;" v-model:value="dateFrom" :allow-clear="false" placeholder="Выберите неделю"
                       show-today :show-week-number="false" v-model:open="isCalendarOpen"
                       :disabled-date="disabledDateTo"
                       format="DD MMMM" value-format="YYYY-MM-DD" @change="isCalendarOpen = false">
          <a-input :value="dateWeekStr" class="week-picker" readonly @click="isCalendarOpen = true">
            <template #suffix>
              <span class="disabled-color-text" @click="isCalendarOpen = true"><calendar-outlined/></span>
            </template>
          </a-input>
          <template #renderExtraFooter>
            <div class="text-center">
              <a-button type="link" @click="goToday">
                <template #icon>
                  <calendar-outlined/>
                </template>
                Сегодня
              </a-button>
              <a-button type="link" @click="isCalendarOpen = false">Закрыть</a-button>
            </div>
          </template>
        </a-week-picker>
        <a-button size="large" ghost @click="goPrevWeek" :disabled="isPrevWeekDisabled">
          <template #icon>
            <left-outlined :style="{ fontSize: '0.75rem' }"/>
          </template>
        </a-button>
        <a-button size="large" ghost @click="goNextWeek">
          <template #icon>
            <right-outlined :style="{ fontSize: '0.75rem' }"/>
          </template>
        </a-button>
      </div>
    </a-row>
    <!-- Календарь -->
    <div>
      <vue-cal id="vuecal"
               style="height: auto"
               v-bind="calendarOptions" :events="events" @ready="calendarOnReady"
               :drag-to-create-event="allowAdd === 'edit'"

               @touchstart="calendarStartDragClick"
               @touchend="handleTouchEndDrag"

               :on-event-create="calendarStartDrag"
               @event-drag-create="calendarStopDrag"
               @cell-dblclick="calendarOnCellClick"
               @event-focus="calendarEventFocus"
               :class="{'has-scroll-ipad': false}"
      >
        <template #weekday-heading="{ heading }">

          <div :class="{ today: isToday(heading.date) }">
            {{ heading.label }} <br>
            <strong>{{ headingDateFormat(heading.date) }}</strong>
          </div>
        </template>
        <template #event="{ event }">

          <div class="vuecal__event-title" v-if="event.title">{{ event.title }}</div>
          <div class="vuecal__event-time">
            <div v-if="!event.class && false">
              {{ eventToEditDurationStr }}
            </div>
            <div v-else>
              <a-tooltip
                  v-if="event.endTimeMinutes - event.startTimeMinutes < 30 && event.class.indexOf('tooltipped') !== -1">
                <template #title>
                  {{ moment(event.start, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }} &ndash;
                  {{ moment(event.endReal || event.end, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }}
                </template>
                <div>&nbsp;</div>
              </a-tooltip>
              <span v-else>
              {{ moment(event.start, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }} &ndash;
              {{ moment(event.endReal || event.end, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm') }}
            </span>
            </div>
          </div>
        </template>
      </vue-cal>
      <div id="btn-edit" :style="{opacity: allowAdd === 'hidden' ? '0' : '1', width: `${calcWidth}px`}" @click="allowAdd = (allowAdd === 'edit') ? 'scroll' : 'edit'">
        {{ allowAdd === 'edit' ? 'Прокрутка' : 'Бронирование' }}
      </div>
    </div>

    <!-- Модалка бронирования -->
    <a-modal v-model:visible="modalEditEvent.visible"
             :title="modalEditEvent?.reservationId ? 'Ваша бронь' : 'Вы выбрали'"
             width="400px" @cancel="modalAddCancel">
      <reservation-preview :name="modalEditEvent.object?.name" :code="modalEditEvent.object?.code"
                           :preview-url="modalEditEvent.object?.previewUrl"/>

      <a-descriptions bordered :column="1" size="small">
        <a-descriptions-item label="Локация">
          {{ renderObjectPath(modalEditEvent.object?.path || [], false, ', ') }}
        </a-descriptions-item>
        <a-descriptions-item label="Начало" v-if="modalEditEvent?.reservationId">
          {{ moment(modalEditEvent.dateFromDate).format('DD.MM.YYYY') }} | {{ modalEditEvent.dateFromTime }}
        </a-descriptions-item>
        <a-descriptions-item label="Окончание" v-if="modalEditEvent?.reservationId">
          {{ moment(modalEditEvent.dateToDate).format('DD.MM.YYYY') }} | {{ modalEditEvent.dateToTime }}
        </a-descriptions-item>
      </a-descriptions>

      <!-- Редактирование разрешено -->
      <a-form layout="vertical" :model="modalEditEvent.form" ref="modalAddRef" v-if="!modalEditEvent?.reservationId">
        <a-divider/>
        <a-form-item label="Начало" name="date">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-date-picker v-model:value="modalEditEvent.dateFromDate" placeholder="Дата" size="large"
                             @change="formFromDateChanged" class="w-100" format="DD.MM.YY" value-format="YYYY-MM-DD"/>
            </a-col>
            <a-col :span="12">
              <smart-time-field v-model="modalEditEvent.dateFromTime"/>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item label="Окончание" name="date">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-date-picker v-model:value="modalEditEvent.dateToDate" placeholder="Дата" size="large" class="w-100"
                             format="DD.MM.YY" value-format="YYYY-MM-DD"/>
            </a-col>
            <a-col :span="12">
              <smart-time-field v-model="modalEditEvent.dateToTime"/>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item label="Участники" v-if="object?.objectType?.userInvitationAvailable">
          <invite-user-list :list="modalEditEvent.form.invitedUsers" :readonly="!modalEditEvent.interactive"/>
        </a-form-item>
      </a-form>

      <div
          v-else-if="modalEditEvent.form.invitedUsers.length > 0 || (modalEditEvent?.canInvite && object?.objectType?.userInvitationAvailable)">
        <div class="ant-form-item-label grayscale-gray-color-text">Участники</div>
        <invite-user-list :list="modalEditEvent.form.invitedUsers"
                          :readonly="!modalEditEvent?.canInvite || !object?.objectType?.userInvitationAvailable || !modalEditEvent.interactive"
                          @change="onInvitedUsersChanged($event, modalEditEvent?.reservationId)"/>
      </div>

      <template #footer>
        <a-button v-if="modalEditEvent?.reservationId && modalEditEvent?.canCancel" size="large" type="danger"
                  :loading="modalEditEvent.loading" :style="{ minWidth: '50%' }" @click="handleDeleteReservation">
          Отменить
        </a-button>
        <a-button v-else-if="!modalEditEvent?.reservationId && modalEditEvent.interactive" key="submit" size="large"
                  type="primary" block :loading="modalEditEvent.loading" @click="handleSetReservationSubmit"
                  :disabled="!modalEditEvent.dateFromDate || !modalEditEvent.dateFromTime || !modalEditEvent.dateToDate || !modalEditEvent.dateToTime">
          Забронировать
        </a-button>
        <div v-else :style="{ height: '0' }">&nbsp;</div>
      </template>
    </a-modal>

  </section>
</template>

<script>
// https://antoniandre.github.io/vue-cal/?ref=madewithvuejs.com
import VueCal from 'vue-cal'
import 'vue-cal/dist/drag-and-drop.js'
import 'vue-cal/dist/vuecal.css'
import {
  LeftOutlined, RightOutlined, CalendarOutlined,
} from '@ant-design/icons-vue';
import {computed, reactive, ref, unref} from "@vue/reactivity";
import {nextTick, onMounted, watch} from "@vue/runtime-core";
import {requestAPI as reservationAPI} from "@/compositions/reservations";
import {getOneData as getOneObject} from "@/compositions/objects";
import moment from "moment";
import {notification} from "ant-design-vue";
import ReservationPreview from "@/components/admin/reservation/ReservationPreview";
import SmartTimeField from "@/components/admin/shared/ul/SmartTimeField";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import InviteUserList from "./InviteUserList";

export default {
  name: 'ReservationCalendar',
  props: {
    object: Object,
    disabled: Boolean,
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const user = computed(() => store.state.cabinet.user);

    const ruCustom = {
      weekDays: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
      weekDaysShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
      years: "Годы",
      year: "Год",
      month: "Месяц",
      week: "Неделя",
      day: "День",
      today: "Сегодня",
      noEvent: "Свободно",
      allDay: "Весь день",
      deleteEvent: "Удалить",
      createEvent: "Создать событие",
      dateFormat: "dddd D MMMM YYYY"
    };
    const allowAdd = ref('hidden');
    const isMobile = window.innerWidth < 1200;
    const calcWidth = ref(10)
    const dateFrom = ref(null);
    const timeout = ref(null);

    const dateWeek = computed(() => {
      const today = moment(unref(dateFrom), 'YYYY-MM-DD');
      if (today.isValid()) {
        const start = today.startOf('week');
        const end = today.endOf('week');
        const startStr = today.startOf('week').format('DD MMMM');
        const endStr = today.endOf('week').format('DD MMMM');
        const dateFrom = today.startOf('week').format('YYYY-MM-DD 00:00:00');
        const dateTo = today.endOf('week').format('YYYY-MM-DD 23:59:59');
        return {start, end, startStr, endStr, dateFrom, dateTo};
      }
      return 'Выберите неделю';
    });
    const dateWeekStr = computed(() => `${unref(dateWeek)?.startStr} — ${unref(dateWeek)?.endStr}`);
    const objectDatetime = ref(null);
    const isCalendarOpen = ref(false);

    const calendarOptions = reactive({
      // Данные диапазона дат
      'selected-date': dateFrom,
      'time-from': 0,
      'time-to': 24 * 60,
      'time-step': 60,
      'snap-to-time': 10,
      'time-cell-height': 40,
      // Внешний вид
      'locale': ruCustom,
      'hide-view-selector': true,
      'hide-title-bar': true,
      'dblclick-to-navigate': false,
      'active-view': 'week',
      'disable-views': ['years', 'year', 'month', 'day'],
      'drag-to-create-event': true,
      'drag-to-create-threshold': 10,
      // События
      'editable-events': {title: false, drag: true, resize: true, delete: false, create: true},
    });
    if (props.disabled) {
      calendarOptions["editable-events"] = false;
    }
    // События: статичные static и запрещенные disabled
    const events = ref([]);
    const eventToEdit = ref(undefined);
    const eventToEditDuration = computed(() => unref(eventToEdit)?.endTimeMinutes - unref(eventToEdit)?.startTimeMinutes);
    const eventToEditStart = computed(() => unref(eventToEdit)?.startTimeMinutes);
    const eventToEditEnd = computed(() => unref(eventToEdit)?.endTimeMinutes);

    const modalAddRef = ref(null);
    const modalEditEvent = reactive({
      object: undefined,
      reservationId: undefined,
      interactive: false,
      visible: false,
      loading: false,
      canCancel: false,
      canInvite: false,
      dateFromDate: undefined,
      dateFromTime: undefined,
      dateToDate: undefined,
      dateToTime: undefined,
      form: {
        objectId: undefined,
        invitedUsers: [],
      },
    });

    const {renderObjectPath} = getOneObject();
    const {updateReservation, removeReservation, fetchFreeSlotsForObject} = reservationAPI();
    const loading = ref(false);

    onMounted(async () => {
      if (route?.params?.dateFrom && route?.params?.dateFrom !== 'null') {
        dateFrom.value = moment(route?.params?.dateFrom).format('YYYY-MM-DD');
      } else {
        dateFrom.value = moment().format('YYYY-MM-DD');
      }
      //
      modalEditEvent.object = props.object;
      modalEditEvent.form.objectId = props.object?.id;
      objectDatetime.value = moment(props.object?.currentDatetime, 'YYYY-MM-DDTHH:mm:ss');
      // Заполнить данные из внешней формы
      if (route?.params?.dateFrom && route?.params?.timeFrom && route?.params?.dateTo && route?.params?.timeTo) {
        modalEditEvent.dateFromDate = route.params.dateFrom;
        modalEditEvent.dateFromTime = route.params.timeFrom;
        modalEditEvent.dateToDate = route.params.dateTo;
        modalEditEvent.dateToTime = route.params.timeTo;
        modalEditEvent.interactive = true;
        modalEditEvent.visible = true;
      }


      function resize() {
        setTimeout(() => {
          if (isMobile) {
            const vueCalSize = document.querySelector('.vuecal')?.clientWidth;

            if (vueCalSize) {
              calcWidth.value = vueCalSize;
              allowAdd.value = 'scroll';
            }
          } else {
            allowAdd.value = 'edit';
          }
        }, 300)
      }

      resize();
      window.addEventListener('resize', resize);
    });

    async function loadData() {
      try {
        loading.value = true;
        const breakMinutes = props.object?.objectReservationPolicy?.breakMinutes || 15;

        const res = await fetchFreeSlotsForObject(props.object?.id, {
          dateFrom: unref(dateWeek)?.dateFrom,
          dateTo: unref(dateWeek)?.dateTo,
        });

        // Добавляем события на карту
        const realIntervals = [];
        const disabledIntervalsBefore = [];
        const disabledIntervalsAfter = [];
        const disabledByPolitic = [];
        const nowLine = [];

        res.data.reserved.forEach((schedule) => {
          schedule?.reservedIntervals.forEach((interval) => {
            realIntervals.push({
              start: moment(interval?.from, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm'),
              end: moment(interval?.to, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm'),
              class: `tooltipped static ${unref(user)?.id === interval?.userId ? 'mine interactive' : 'other'}`,
              deletable: false,
              resizable: false,
              draggable: false,
              reservationId: interval?.id,
              invitedUsers: interval?.invitedUsers,
              reservationStatus: interval?.status,
              canCancel: (interval?.actions || []).indexOf('cancel') >= 0,
              canInvite: (interval?.actions || []).indexOf('invite') >= 0,
            });
          });
        });

        // Добавляем запрещенные интервал ДО
        res.data.reserved.forEach((schedule) => {
          schedule?.reservedIntervals.forEach((interval) => {
            // Проверяем, пересекаются ли интервалы, т.е. входит ли его конец в чей-то диапазон начал
            let beforeIntervalStart = moment(interval?.from, 'YYYY-MM-DDTHH:mm:ss').add(-breakMinutes, 'minutes').format('YYYY-MM-DD HH:mm');
            const intersectInterval = realIntervals.find((realInterval) => realInterval.start <= beforeIntervalStart && beforeIntervalStart <= realInterval.end);
            if (intersectInterval) {
              beforeIntervalStart = intersectInterval?.end;
            }
            disabledIntervalsBefore.push({
              start: beforeIntervalStart,
              end: moment(interval?.from, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm'),
              class: 'disabled start',
              deletable: false,
              resizable: false,
              draggable: false,
            });
          });
        });

        // Добавляем запрещенные интервал ПОСЛЕ
        res.data.reserved.forEach((schedule) => {
          schedule?.reservedIntervals.forEach((interval) => {
            console.log(interval);
            const afterIntervalStart = moment(interval?.to, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm');
            let afterIntervalEnd = moment(interval?.to, 'YYYY-MM-DDTHH:mm:ss').add(+breakMinutes, 'minutes').format('YYYY-MM-DD HH:mm');

            // Проверяем, пересекаются ли интервалы, т.е. входит ли его конец в чей-то диапазон начал
            let intersectInterval = disabledIntervalsBefore.find((beforeInterval) => beforeInterval.start <= afterIntervalEnd && afterIntervalEnd <= beforeInterval.end);
            if (intersectInterval) {
              afterIntervalEnd = intersectInterval?.start;
            }
            // Повторно проверим пересечение с самими интервалами
            intersectInterval = realIntervals.find((realInterval) => realInterval.start <= afterIntervalEnd && afterIntervalEnd <= realInterval.end);
            if (intersectInterval) {
              afterIntervalEnd = intersectInterval?.start;
            }

            if (afterIntervalStart !== afterIntervalEnd) {
              disabledIntervalsAfter.push({
                start: afterIntervalStart,
                end: afterIntervalEnd,
                class: 'disabled end' + (!props.disabled && interval.status === 'reserved' ? ' interactive' : ''),
                deletable: false,
                resizable: false,
                draggable: false,
              });
            }
          });
        });

        // Добавляем интервалы, запрещенные политиками
        let availableStartAtMinutes = 24 * 60;
        let availableEndTillMinutes = 0;
        res.data.available.forEach((schedule) => {
          const dates = [moment(schedule?.date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm')];
          const dayEnd = moment(schedule?.date, 'YYYY-MM-DDTHH:mm:ss').set({hours: 23, minutes: 59, seconds: 59});

          // Добавляем линию текущего времени
          const dayNowLine = moment(schedule?.date, 'YYYY-MM-DDTHH:mm:ss').set({
            hour: unref(objectDatetime).hours(),
            minute: unref(objectDatetime).minutes()
          });
          nowLine.push({
            start: dayNowLine.format('YYYY-MM-DD HH:mm'),
            end: dayNowLine.add(5, 'minutes').format('YYYY-MM-DD HH:mm'),
            class: 'now-line',
            deletable: false,
            resizable: false,
            draggable: false,
            background: true,
          });

          // Преобразуем доступные интервалы в запрещенные, добавив начало и конец
          schedule?.availableIntervals.forEach((interval) => {
            const eventStart = moment(interval?.from, 'YYYY-MM-DDTHH:mm:ss');
            const eventStartMinutes = eventStart.hours() * 60 + eventStart.minutes();
            if (eventStartMinutes < availableStartAtMinutes) {
              availableStartAtMinutes = eventStartMinutes;
            }
            dates.push(eventStart.format('YYYY-MM-DD HH:mm'));
            let eventEnd = moment(interval?.to, 'YYYY-MM-DDTHH:mm:ss');
            if (eventEnd > dayEnd) {
              eventEnd = dayEnd;
            }
            dates.push(eventEnd.format('YYYY-MM-DD HH:mm'));
            const eventEndMinutes = eventEnd.hours() * 60 + eventEnd.minutes();
            if (eventEndMinutes > availableEndTillMinutes) {
              availableEndTillMinutes = eventEndMinutes;
            }
          });
          dates.push(dayEnd.format('YYYY-MM-DD HH:mm'));
          for (let i = 0; i < dates.length; i += 2) {
            if (dates[i] !== dates[i + 1]) {
              disabledByPolitic.push({
                start: dates[i],
                end: dates[i + 1],
                class: 'politic-disabled',
                deletable: false,
                resizable: false,
                draggable: false,
                background: true,
              });
            }
          }
        });
        calendarOptions["time-from"] = availableStartAtMinutes;
        calendarOptions["time-to"] = availableEndTillMinutes;

        events.value = [...realIntervals, ...disabledIntervalsBefore, ...disabledIntervalsAfter, ...disabledByPolitic, ...nowLine];

      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }

    watch(dateWeekStr, () => {
      loadData();
    })

    // Тайтл в создаваемом событии на карте
    const eventToEditDurationStr = ref('—');
    // Следим за длительностью создаваемого элемента и привязываем к разрешенному интервалу
    // eslint-disable-next-line no-unused-vars
    watch([eventToEditDuration, eventToEditStart, eventToEditEnd], ([duration, eventStartMinutes, eventEndMinutes], [durationOld, eventStartMinutesOld, eventEndMinutesOld]) => {
      if (duration <= 0) duration = Math.abs(duration) || 1;
      // Скорректировать конец интервала исходя из политик бронирования
      const swapInterval = props.object?.objectReservationPolicy?.intervalMinutes || 10;
      const swapIntervalNumber = Math.ceil(duration / swapInterval);
      const intervalMinutesRounded = (swapIntervalNumber * swapInterval);

      let start, end;
      // console.log('interval: ', intervalMinutesRounded);

      // Скорректировать отображаемое время
      // Изменяется окончание - тащим за низ
      if (eventEndMinutes !== eventEndMinutesOld) {
        // console.log('tail')
        start = moment(eventToEdit.value.start);
        end = start.clone().add(intervalMinutesRounded, 'minutes');

        eventToEdit.value.endReal = end.toDate();
        // Нельзя отображать конце элемента на другой день. Плагин ломается.
        if (moment(eventToEdit.value.start).format('DD') !== end.format('DD')) {
          end = start.clone().set({hours: 23, minutes: 59});
        }
        // Нельзя выходить за пределы допустимого интервала
        const dayEndMinutes = calendarOptions["time-to"];
        if (eventEndMinutes >= dayEndMinutes) {
          end.set({date: end.date(), hours: Math.ceil(dayEndMinutes / 60), minutes: Math.ceil(dayEndMinutes % 60)});
          eventToEdit.value.start = start.toDate();
          eventToEdit.value.end = end.toDate();
          eventToEdit.value.endReal = end.toDate();
          drawEventToEditTitle(start, end);
          return calendarStopDrag();
        }

        eventToEdit.value.start = start.toDate();
        eventToEdit.value.end = end.toDate();
        eventToEdit.value.endTimeMinutes = eventStartMinutes + intervalMinutesRounded;
        drawEventToEditTitle(start, end);

      } else if (eventStartMinutes !== eventStartMinutesOld) { // Тащим за верх
        console.log('head');
        end = moment(eventToEdit.value.end);
        start = end.clone().add(-intervalMinutesRounded, 'minutes');
        eventToEdit.value.startTimeMinutes = eventEndMinutes - intervalMinutesRounded;

        // Нельзя раньше минимально разрешенноё даты - сразу же открываем бронирование
        const dayStartMinutes = calendarOptions["time-from"];
        if (eventStartMinutes <= dayStartMinutes) {
          start.set({
            date: end.date(),
            hours: Math.ceil(dayStartMinutes / 60),
            minutes: Math.ceil(dayStartMinutes % 60)
          });
          eventToEdit.value.start = start.toDate();
          eventToEdit.value.end = end.toDate();
          eventToEdit.value.endReal = end.toDate();
          drawEventToEditTitle(start, end);
          return calendarStopDrag();
        }
        drawEventToEditTitle(start, end);
      }


    })

    function drawEventToEditTitle(start, end) {
      eventToEditDurationStr.value = start.format('HH:mm') + ' - ' + end.format('HH:mm');
    }

    function goPrevWeek() {
      dateFrom.value = moment(unref(dateFrom), 'YYYY-MM-DD').subtract(1, 'week').format('YYYY-MM-DD');
    }

    function goNextWeek() {
      dateFrom.value = moment(unref(dateFrom), 'YYYY-MM-DD').add(1, 'week').format('YYYY-MM-DD');
    }

    function goToday() {
      dateFrom.value = moment().format('YYYY-MM-DD');
      isCalendarOpen.value = false;
    }

    function headingDateFormat(date) {
      return moment(date).format('DD MMMM');
    }

    function calendarOnReady() {
      console.log('calendarOnReady')
      const calendar = document.querySelector('#vuecal .vuecal__bg')
      const hours = 8;
      calendar.scrollTo({top: hours * calendarOptions["time-cell-height"], behavior: 'smooth'})
    }

    // eslint-disable-next-line no-unused-vars
    function calendarStartDrag(event) {
      console.log(`calendarStartDrag ${allowAdd.value}`);
      if (allowAdd.value !== 'edit') return null;
      console.log('calendarStartDrag', event);
      eventToEdit.value = event;
      return event;
    }

    function calendarStartDragClick(event) {
      return event;
    }

    function handleTouchEndDrag() {
      clearTimeout(timeout.value);
      timeout.value = null;
      ///  document.removeEventListener('touchend', handleTouchEndDrag);
    }

    function calendarStopDrag() {
      const {start, endReal: end} = unref(eventToEdit);
      renderModal({start, end});
    }

    // eslint-disable-next-line no-unused-vars
    function calendarOnCellClick(event) {
      // isLongPress.value = false;
      // console.log(`isLongPress ${isLongPress.value}`);
      if (props.disabled) return;
      if (modalEditEvent.visible) return;
      let start, end;
      // Клик на заголовке
      if (event?.date) {
        return;
      }
      // Просто кликом
      else if (event) {
        start = moment(event);
        const remainder = (start.minute() % 10); // округляем до ближайшего
        start.add(-remainder, 'minutes');
        end = start;
      } else {
        return console.error('Дата не выбрана');
      }

      if (start.diff(end, 'minutes') === 0) {
        end = moment(start).add(props.object?.objectReservationPolicy?.intervalMinutes || 10, 'minutes').format('YYYY-MM-DD HH:mm');
      }

      // добавляем объект в качестве предпросмотра на карту
      events.value.push({
        start: start.format('YYYY-MM-DD HH:mm'),
        end,
        class: `static mine hide-time create-template`,
      });

      renderModal({start, end});
    }

    function calendarEventFocus(event) {
      console.log('calendarEventFocus');
      if (event?.class.indexOf('interactive') >= 0) {
        // Не редактируем, а создаем бронирование после перерыва
        if (event?.class.indexOf('end') >= 0) {
          const end = moment(event?.end).add(props.object?.objectReservationPolicy?.intervalMinutes || 10, 'minutes').format('YYYY-MM-DD HH:mm');
          return renderModal({start: event?.end, end});
        }
        renderModal(event);
      }
    }

    function renderModal(event) {
      if (modalEditEvent.visible) return;
      console.log('modal: ', event);
      modalEditEvent.interactive = !event.canStart
          && !props.disabled
          && (!event?.reservationId || event.reservationStatus === 'reserved'); // Если новый или забронирован
      modalEditEvent.reservationId = event?.reservationId;
      modalEditEvent.dateFromDate = moment(event.start).format('YYYY-MM-DD');
      modalEditEvent.dateToDate = moment(event.end).format('YYYY-MM-DD');
      modalEditEvent.dateFromTime = moment(event.start).format('HH:mm');
      modalEditEvent.dateToTime = moment(event.end).format('HH:mm');
      modalEditEvent.form.invitedUsers = event.invitedUsers || [];
      modalEditEvent.canCancel = event.canCancel;
      modalEditEvent.canInvite = event.canInvite;
      modalEditEvent.visible = true;
    }

    function modalAddCancel() {
      // Удалить временную заявку из списка событий и вызвать перерисовку списка
      events.value = [...(unref(events) || []).filter((event) => event?.class?.indexOf('create-template') === -1)];
    }

    async function handleSetReservationSubmit() {
      try {
        await modalAddRef.value
            .validate()
            .then(async () => {
              // Это проверяется в том числе на этапе валидации
              if (!modalEditEvent.dateFromDate || !modalEditEvent.dateFromTime || !modalEditEvent.dateToDate || !modalEditEvent.dateToTime) return;

              const dateFromFull = moment(`${modalEditEvent.dateFromDate} ${modalEditEvent.dateFromTime}`, 'YYYY-MM-DD HH:mm');
              const dateToFull = moment(`${modalEditEvent.dateToDate} ${modalEditEvent.dateToTime}`, 'YYYY-MM-DD HH:mm');

              if (!dateFromFull.isValid() || !dateToFull.isValid() || dateFromFull >= dateToFull) {
                return notification.error({
                  message: 'Ошибка',
                  description: 'Проверьте, правильно ли выбраны даты',
                });
              }

              modalEditEvent.loading = true;
              const res = await updateReservation({
                ...modalEditEvent.form,
                dateFrom: dateFromFull.format('YYYY-MM-DD HH:mm:00'),
                dateTo: dateToFull.format('YYYY-MM-DD HH:mm:00'),
              });
              if (res?.id) {
                notification.success({
                  message: 'Успех',
                  description: `Вы забронировали ${modalEditEvent.object?.name}`,
                });
                modalEditEvent.visible = false;
                loadData();
              } else {
                notification.error({
                  message: 'Ошибка',
                  description: res?.message || 'Сохранение не удалось',
                });
              }
            });

      } catch (e) {
        console.error(e);
      } finally {
        modalEditEvent.loading = false;
      }
    }

    async function handleDeleteReservation() {
      try {
        modalEditEvent.loading = true;
        await removeReservation(modalEditEvent?.reservationId);
        notification.success({
          message: 'Успех',
          description: `Бронирование успешно удалено`,
        });
        modalEditEvent.visible = false;
        loadData();
      } catch (e) {
        console.error(e);
      } finally {
        modalEditEvent.loading = false;
      }
    }

    function formFromDateChanged() {
      if (modalEditEvent.dateToDate < modalEditEvent.dateFromDate) {
        nextTick(() => modalEditEvent.dateToDate = modalEditEvent.dateFromDate);
      }
    }

    function isToday(date) {
      return moment(date).format('YYYY-MM-DD') === unref(objectDatetime)?.format('YYYY-MM-DD');
    }

    function disabledDateTo(current) {
      return current && current < moment();
    }

    const isPrevWeekDisabled = computed(() => {
      return unref(dateFrom) <= moment().format('YYYY-MM-DD 00:00:00');
    });

    async function onInvitedUsersChanged(invitedUsers, reservationId) {
      await updateReservation({invitedUsers}, reservationId);
      notification.success({
        message: 'Сохранено',
        description: `Список приглашённых пользователей обновлён`,
      });
      await loadData();
    }

    return {
      timeout,
      allowAdd,
      isMobile,
      calcWidth,
      moment, loading,
      renderObjectPath, calendarOptions, dateFrom, dateWeekStr,
      events, eventToEdit, eventToEditDurationStr,
      goToday, goPrevWeek, goNextWeek, headingDateFormat,
      calendarOnReady, calendarOnCellClick, calendarEventFocus, calendarStartDrag, calendarStopDrag,
      modalEditEvent, modalAddRef, modalAddCancel,
      handleSetReservationSubmit, handleDeleteReservation,
      formFromDateChanged, isToday, isCalendarOpen, disabledDateTo, isPrevWeekDisabled,
      onInvitedUsersChanged,
      calendarStartDragClick, handleTouchEndDrag,
    };
  },
  components: {
    InviteUserList,
    SmartTimeField,
    ReservationPreview,
    VueCal, LeftOutlined, RightOutlined, CalendarOutlined,
  }
}
</script>

<style lang="less">
.reservation-calendar .vuecal__cell--selected {
  background: fade(@primary-color, 12%) !important;
}

#btn-edit {
  position: fixed; bottom: 20px; background-color: rgba(219, 219, 219, 0.7);
  z-index: 1000; width: 500px; padding: 10px;
  text-align: center;
  transition: opacity 0.5s ease;
}

.reservation-calendar {
  .vuecal {
    &__weekdays-headings {
      padding-right: 0;
    }

    &__heading {
      background-color: @background-primary;
      color: @grayscale-gray;
      font-size: 13px;
      line-height: 14px;
      padding: 0;
      height: 44px;

      .today {
        background: @primary-color;
        color: white;
        padding: 0.5rem 0;
        width: 100%;
        height: 44px;
      }
    }

    &__cell {
      cursor: pointer;

      &--current,
      &--today {
        background: fade(@primary-color, 12%);
      }

      &--selected {
        background: fade(@grayscale-gray, 6%);
      }
    }

    &__event {
      background: fade(#008DD2, 100%);
      left: 4px !important;
      width: ~"calc(100% - 7px)" !important;
      border-radius: 2px;
      border: 1px solid white;
      color: white;
      transition: none;

      &-time {
        font-size: 10px;
        position: absolute;
        width: 100%;
        top: 50%;
        margin-top: -7px;
      }

      &.static {
        &.mine {
          background-color: fade(#008DD2, 40%);
          cursor: pointer;
          z-index: 5 !important;

          &:hover {
            background-color: fade(#008DD2, 50%);
          }

          &.vuecal__event--focus {
            background-color: fade(#008DD2, 60%);
          }
        }

        &.other {
          cursor: not-allowed;
          background-color: fade(#909399, 40%);

          .vuecal__event-time {
            display: none;
          }
        }
      }

      &.disabled {
        background-color: #BCBCBE;
        cursor: not-allowed;

        &.start {
          pointer-events: none;
        }

        &.end {

          .vuecal__event-time {
            display: none;
          }

          &.interactive {
            cursor: pointer;

            &:hover {
              background-color: #008DD2;
            }
          }
        }

        &-debug {
          outline: 1px dashed red !important;
        }

        .vuecal__event-time {
          display: none;
        }
      }

      &.politic-disabled {
        background: repeating-linear-gradient(45deg, transparent, transparent 10px, fade(#909399, 20%) 10px, fade(#909399, 20%) 20px);
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: not-allowed;
        left: 0 !important;
        width: 100% !important;
        border: none;

        box-shadow: none !important;
        z-index: 1 !important;

        .vuecal__event-time {
          display: none;
        }
      }

      &.now-line {
        background: @error-color;
        height: 1px !important;
        border: none !important;
        left: 0 !important;
        width: 100% !important;
        z-index: 10;

        .vuecal__event-time {
          display: none;
        }
      }

      &.create-template {
        background-image: url('/images/icons/plus-outlined.svg');
        background-position: center center;
        background-repeat: no-repeat;
      }

      &.hide-time {
        .vuecal__event-time {
          display: none;
        }
      }
    }

    &__no-event {
      font-size: 12px;
    }

    &__time-cell {
      text-align: center;

      &-label {
        font-size: 13px;
      }
    }

    &__now-line {
      display: none;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 12px;
    background-color: transparent !important;
    border: 1px solid rgba(196, 196, 212, 0.48);
  }

  .ant-input {
    background-color: transparent !important;
  }

  .week-picker {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
</style>
