<template>
  <div style="display: flex; flex-wrap: wrap; gap: 8px">
    <div class="comfort-system" v-for="system in ComfortSystems" :key="system?.id" @click="systemClick(system?.id)">
      <img
          :src="(isActive && system?.isActiveOnComfort) || (!isActive && system?.isActive) ? system?.icon : system.iconOff"
          alt="4Space"/>
      <div style="
                    margin: 0;
                    margin-left: 4px;
                    display: flex;
                    flex-direction: column;
                    align-self: start;
                    justify-content: center;
                    height: 100%;
                ">
        <p class="comfort-name">{{ system?.name }}</p>
        <p style="margin: 0">
          {{ (isActive && system?.isActiveOnComfort) || (!isActive && system?.isActive) ? (system.altOn ?? "Вкл") : (system.altOff ?? "Выкл") }}
        </p>
      </div>
    </div>

    <div @click="systemSoundClick" class="comfort-system">
      <img :src="isActiveSystemSound ? '/images/icons/sound.svg' : '/images/icons/no-sound.svg'" alt="4Space"/>
      <div style="
                    margin: 0;
                    margin-left: 4px;
                    display: flex;
                    flex-direction: column;
                    align-self: start;
                    justify-content: center;
                    height: 100%;
                ">
        <p class="comfort-name">Маскирование звука</p>
        <p style="margin: 0">{{ isActiveSystemSound ? "Вкл" : "Выкл" }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "@vue/reactivity";

export default {
  name: "ComfortSystems",
  props: {isActiveSystem: Boolean},
  setup() {
    const isActiveSystemSound = ref(false);
    const ComfortSystems = ref([
      {
        id: 1,
        name: "Климат-контроль",
        icon: "/images/icons/snowflake.svg",
        isActive: false,
        iconOff: "/images/icons/snowflakeOff.svg",
        isActiveOnComfort: true,
        altOn: 'Любимый',
        altOff: 'Оптимальный',
      },
      {
        id: 2,
        name: "Вентиляция",
        icon: "/images/icons/fan.svg",
        isActive: false,
        iconOff: "/images/icons/fanOff.svg",
        isActiveOnComfort: false,
        altOn: 'Активная',
        altOff: 'Пассивная',
      },
      {
        id: 3,
        name: "Проветривание",
        icon: "/images/icons/air.svg",
        isActive: false,
        iconOff: "/images/icons/airOff.svg",
        isActiveOnComfort: true,
      },
      {
        id: 4,
        name: "Увлажнение воздуха",
        icon: "/images/icons/air-freshener.svg",
        isActive: false,
        iconOff: "/images/icons/air-freshenerOff.svg",
        isActiveOnComfort: true,
      },
      {
        id: 5,
        name: "Ароматизация помещения",
        icon: "/images/icons/filter.svg",
        isActive: false,
        iconOff: "/images/icons/filterOff.svg",
        isActiveOnComfort: true,
      },
      {
        id: 6,
        name: "Теплый пол",
        icon: "/images/icons/heat.svg",
        isActive: false,
        iconOff: "/images/icons/heatOff.svg",
        isActiveOnComfort: true,
      },
      {
        id: 8,
        name: "Освещение (биодинамика)",
        icon: "/images/icons/light.svg",
        isActive: false,
        iconOff: "/images/icons/lightOff.svg",
        isActiveOnComfort: true,
      },
      {
        id: 9,
        name: "Шторы",
        icon: "/images/icons/curtains.svg",
        isActive: false,
        iconOff: "/images/icons/curtainsOff.svg",
        isActiveOnComfort: false,
        altOn: 'Открыты',
        altOff: 'Закрыты'
      },
    ]);

    function systemSoundClick() {
      this.isActiveSystemSound = !this.isActiveSystemSound;
    }

    function systemClick(id) {
      this.ComfortSystems.find((item) => item.id === id).isActive = !this.ComfortSystems.find((item) => item.id === id).isActive;
    }

    return {ComfortSystems, systemClick, systemSoundClick, isActiveSystemSound};
  },


  watch: {
    isActiveSystem() {
      this.isActive = this.isActiveSystem;
    },
  },
};
</script>

<style lang="less">
.comfort-name {
  margin: 0;
  color: #1a1a23;
  font-family: Aeroport;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 100% */
  letter-spacing: -0.32px;
}

.comfort-system {
  cursor: pointer;
  width: 32.5%;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 4px 16.333px 4px 4px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #f2f2f7;
}
</style>
